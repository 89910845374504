<template>
  <div style="width: 99%">
    <el-row :gutter="20">
      <el-col :span="4">
        <el-date-picker
            style="width: 100%"
            v-model="form.date"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            type="daterange"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </el-col>
      <el-col :span="3">
        <el-select v-model="form.serviceId" placeholder="选择客服" style="width: 100%;" clearable>
          <el-option
              v-for="item in serviceIdArray"
              :key="item.value"
              :label="item.name"
              :value="item.value">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-button type="primary" @click="search">搜索</el-button>
      </el-col>
    </el-row>
    <!--    第一个表格-->
    <el-row :gutter="20">
      <p class="title">{{ dateString }}渠道客资分析表</p>
      <el-col :span="12">
        <div id="sourceCusView"/>
      </el-col>
      <el-col :span="12">
        <el-table
            :data="sourceCusData"
            border
            row-key="id"
            show-summary
            ref="table1"
            :cell-style="cellStyleChange"
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
            :default-sort="{prop: 'sourceCount', order: 'descending'}"
            style="width: 100%">
          <el-table-column prop="sourceName" header-align="center" label="页面名称" align="center"/>
          <el-table-column prop="sourceCount" label="客资数" align="center"/>
        </el-table>
      </el-col>
    </el-row>
    <el-divider/>
    <!--    第二个表格-->
    <el-row :gutter="20">
      <p class="title">{{ dateString }}渠道到店分析表</p>
      <el-col :span="12">
        <div id="sourceCusArrivalView"/>
      </el-col>
      <el-col :span="12">
        <el-table
            :data="sourceCusArrivalData"
            border
            row-key="id"
            :cell-style="cellStyleChange"
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
            show-summary
            :default-sort="{prop: 'sourceCount', order: 'descending'}"
            style="width: 100%">
          <el-table-column prop="sourceName" label="页面名称" align="center"/>
          <el-table-column prop="sourceCount" label="到店数" align="center"/>
          <el-table-column label="客资到店转化率" align="center" #default="scope">
            {{ (scope.row.proportion * 100).toFixed(1)}}%
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-divider/>
<!--    第三个表格-->
    <el-row :gutter="20">
      <p class="title">{{ dateString }}渠道订单分析表</p>
      <el-col :span="12">
        <div id="sourceCusOrderView"/>
      </el-col>
      <el-col :span="12">
        <el-table
            :data="sourceCusOrderData"
            border
            show-summary
            row-key="id"
            :cell-style="cellStyleChange"
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
            :default-sort="{prop: 'sourceCount', order: 'descending'}"
            style="width: 100%">
          <el-table-column prop="sourceName" label="页面名称" align="center"/>
          <el-table-column prop="sourceCount" label="订单数" align="center"/>
          <el-table-column label="到店订单转化率" align="center" #default="scope">
            {{ (scope.row.proportion * 100).toFixed(1)}}%
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-divider/>
    <!--    第四个表格-->
    <el-row :gutter="20">
      <p class="title">{{ dateString }}渠道收款分析表</p>
      <el-col :span="12">
        <div id="sourceCusMoneyView"/>
      </el-col>
      <el-col :span="12">
        <el-table
            :data="sourceCusMoneyData"
            border
            show-summary
            row-key="id"
            :cell-style="cellStyleChange"
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
            :default-sort="{prop: 'sourceCount', order: 'descending'}"
            style="width: 100%">
          <el-table-column prop="sourceName" label="页面名称" align="center"/>
          <el-table-column prop="sourceCount" label="金额" align="center"/>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {Pie} from '@antv/g2plot';

export default {
  name: "customer-source",
  data() {
    return {
      form: {
        date: this.$dateUtils.getMonthStartEndDayStrArray(),
        serviceId: "",
        tenantCrop: localStorage.getItem("tenantCrop")
      },
      serviceIdArray: [],
      dateString: this.$dateUtils.getMonthStartEndDayStr(),

      //渠道客资分析
      sourceCusData: [],
      sourceCusView: {},
      //渠道客资到店分析
      sourceCusArrivalData: [],
      sourceCusArrivalView: {},
      //渠道订单分析
      sourceCusOrderData: [],
      sourceCusOrderView: {},
      //渠道金额分析
      sourceCusMoneyData: [],
      sourceCusMoneyView: {},
    }
  },

  created() {
    this.queryServiceIds();
  },
  mounted() {
    this.initAllView();
  },
  methods: {
    //搜索
    search() {
      this.dateString = this.$dateUtils.stringArrayToString(this.form.date);
      this.querySourceReportsCus();
      this.querySourceReportsArrival();
      this.querySourceReportsOrder();
      this.querySourceReportsMoney();
    },
    //查询客服
    queryServiceIds() {
      this.$selectUtils.queryServiceIds().then(response => {
        this.serviceIdArray = JSON.parse(response.data.data);
      })
    },
    //查询渠道客资分析
    querySourceReportsCus() {
      this.$axios({
        method: 'get',
        url: '/serviceReports/customerSourceReportsCus',
        params: {
          date: this.dateString,
          serviceId: this.form.serviceId,
          tenantCrop: this.form.tenantCrop
        },
      }).then(response => {
        this.sourceCusData = response.data.data;
        this.sourceCusView.changeData(this.sourceCusData);
      });
    },
    //查询渠道客资到店分析
    querySourceReportsArrival() {
      this.$axios({
        method: 'get',
        url: '/serviceReports/customerSourceReportsYesApp',
        params: {
          date: this.dateString,
          serviceId: this.form.serviceId,
          tenantCrop: this.form.tenantCrop
        },
      }).then(response => {
        this.sourceCusArrivalData = response.data.data;
        console.log(this.sourceCusArrivalData)
        this.sourceCusArrivalView.changeData(this.sourceCusArrivalData);
      });
    },
    //查询渠道客资订单分析
    querySourceReportsOrder() {
      this.$axios({
        method: 'get',
        url: '/serviceReports/customerSourceReportsOrder',
        params: {
          date: this.dateString,
          serviceId: this.form.serviceId,
          tenantCrop: this.form.tenantCrop
        },
      }).then(response => {
        this.sourceCusOrderData = response.data.data;
        this.sourceCusOrderView.changeData(this.sourceCusOrderData);
      });
    },
    //查询渠道客资订单分析
    querySourceReportsMoney() {
      this.$axios({
        method: 'get',
        url: '/serviceReports/customerSourceReportsMoney',
        params: {
          date: this.dateString,
          serviceId: this.form.serviceId,
          tenantCrop: this.form.tenantCrop
        },
      }).then(response => {
        this.sourceCusMoneyData = response.data.data;
        this.sourceCusMoneyView.changeData(this.sourceCusMoneyData);
      });
    },
    //加载所有图形表
    initAllView() {
      this.initSourceCusView();
      this.initSourceCusArrivalView();
      this.initSourceCusOrderView();
      this.initSourceCusMoneyView();
    },
    //配置客资图标
    initSourceCusView() {
      this.sourceCusView = new Pie('sourceCusView', {
        appendPadding: 1,
        data: this.sourceCusData,
        mate: {},
        angleField: 'sourceCount',
        colorField: 'sourceName',
        radius: 0.8,
        label: {
          type: 'outer',
          content: '{name} {percentage}',
        },
        legend: {
          flipPage: false,
        },
        interactions: [{type: 'pie-legend-active'}, {type: 'element-active'}],
      })
      this.sourceCusView.render();
      this.querySourceReportsCus();
    },
    //配置到店图标
    initSourceCusArrivalView() {
      this.sourceCusArrivalView = new Pie('sourceCusArrivalView', {
        appendPadding: 1,
        data: this.sourceCusArrivalData,
        mate: {},
        angleField: 'sourceCount',
        colorField: 'sourceName',
        radius: 0.8,
        label: {
          type: 'outer',
          content: '{name} {percentage}',
        },
        legend: {
          flipPage: false,
        },
        interactions: [{type: 'pie-legend-active'}, {type: 'element-active'}],
      })
      this.sourceCusArrivalView.render();
      this.querySourceReportsArrival();
    },
    //配置订单图标
    initSourceCusOrderView() {
      this.sourceCusOrderView = new Pie('sourceCusOrderView', {
        appendPadding: 1,
        data: this.sourceCusOrderData,
        mate: {},
        angleField: 'sourceCount',
        colorField: 'sourceName',
        radius: 0.8,
        label: {
          type: 'outer',
          content: '{name} {percentage}',
        },
        legend: {
          flipPage: false,
        },
        interactions: [{type: 'pie-legend-active'}, {type: 'element-active'}],
      })
      this.sourceCusOrderView.render();
      this.querySourceReportsOrder();
    },
    //配置总金额图标
    initSourceCusMoneyView() {
      this.sourceCusMoneyView = new Pie('sourceCusMoneyView', {
        appendPadding: 1,
        data: this.sourceCusMoneyData,
        mate: {},
        angleField: 'sourceCount',
        colorField: 'sourceName',
        radius: 0.8,
        label: {
          type: 'outer',
          content: '{name} {percentage}',
        },
        legend: {
          flipPage: false,
        },
        interactions: [{type: 'pie-legend-active'}, {type: 'element-active'}],
      })
      this.sourceCusMoneyView.render();
      this.querySourceReportsMoney();
    },
    cellStyleChange(val) {
      if (val.row.sourceType == 2) {
        return {color: "#6395f9"}
      }
    },
  }

}
</script>

<style scoped>
.title {
  margin: 2% 2%;
  font-size: 30px;
}

</style>